.navbarItem {
    text-decoration: none;
    color: black;
    font-weight: bold;
    padding: 3px 10px;
    margin: 0 5px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
}

.navbarItem::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.navbarItemAnimation {
    transform: perspective(1px) translateZ(0);
    color: grey;
    transition: 0.4s;
}

.navbarItemAnimation::before {
    background: #FAD02C;
    clip-path: inset(0 100% 0 0);
    opacity: 1;
    transition: 0.4s;
}

.navbarItemAnimation:hover::before {
    clip-path: inset(0 0 0 0);
}

.navbarItemAnimation:hover {
    color: black;
}
.navbarActive {
    background: #FAD02C;
    color: black;
}

input::placeholder {
    color: white;
}

/*
<Nav.Item style={{width: "100%", fontSize: "20px", padding: "5px 0"}}>
<Link
 to="/"
onClick={() => setOpen(!open)}
className={"navbarItem navbarItemAnimation " + (url === "/" ?" navbarActive" : "")}>
                                                                                   {t("intro")}
</Link>
</Nav.Item>
*/
